<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
      </div>
      <div class="content-body"><section class="row flexbox-container">
<div class="col-12 d-flex align-items-center justify-content-center">
  <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
    <div class="card border-grey border-lighten-3 m-0">
      <div class="card-header border-0">
        <div class="card-title text-center">
          <div class="p-1"><img class="login-logo" src="assets/images/logo/logo.png" alt="branding logo"></div>
          Maverick
        </div>
       
      </div>
      <div class="card-content">
       
        
        <div class="card-body pt-0">
          <form class="form-horizontal" [formGroup]="formGroup" (ngSubmit)="onLogin(formGroup?.value)">
            <fieldset class="form-group floating-label-form-group">
              <label for="user-name">Your Email</label>
              <input type="email" class="form-control" id="user-name" formControlName="email" placeholder="Your Email">
            </fieldset>
            <fieldset class="form-group floating-label-form-group mb-1">
              <label for="user-password">Enter Password</label>
              <input type="password" class="form-control" id="user-password" formControlName="password" placeholder="Enter Password">
            </fieldset>
            <div class="form-group row">
            
              <!-- <div class="col-sm-6 col-12 float-sm-left text-center text-sm-right"><a href="recover-password.html"
                  class="card-link">Forgot Password?</a></div> -->
            </div>
            <button type="submit" [disabled]="isProcessing || formGroup?.invalid" class="btn btn-outline-info btn-block"><i class="ft-unlock"></i>
                <span *ngIf="isProcessing" class="loader"></span>
                <span *ngIf="!isProcessing">Login</span>
            
              
              
              </button>
          </form>
        </div>
      
      </div>
    </div>
  </div>
</div>
</section>

      </div>
    </div>
  </div>