import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.test';

@Injectable({
  providedIn: 'root'
})
export class ConstantValuesService {

  primaryKey: any;

  constructor() { }
  get APP_NAME() { return 'Maverick '; }
  get REQ_SOURSE() { return 'WEB'; }
  get DATE_TIME_FORMAT() { return 'DD/MM/YYYY, hh:mm:ss A'; }
  get SLASH_MM_DD_YYYY_DATE_FORMAT() {return 'MM/DD/YYYY'; }
  get EXPIRTY_DATE_FORMAT() { return 'MM-DD-YYYY'; }
  get DD_MM_YYYY_DATE_FORMAT() { return 'DD-MM-YYYY'; }
  get YYYY_MM_DD_DATE_FORMAT() { return 'YYYY-MM-DD'; }
  get BASE_URL() { return environment.BASE_URL; }
  get LOGIN () { return 'auth/login'; }
  get PRODUCTS_ENDPOINT () { return 'products/'; }


}
