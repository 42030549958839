import { Component } from '@angular/core';
import { UserManagementService } from '../../../services/user-management.service';
import { SharedModule } from '../../../modules/shared/shared.module';
import { Router, RouterModule } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../services/auth.service';
import { HttpResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [SharedModule, RouterModule, CommonModule],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  isProcessing = false;
  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required])
  });

  constructor(
    private userMgmt: UserManagementService,
    private authSdrvice: AuthService,
    private router: Router
  ){}
  onLogin(credential) {
    this.isProcessing = true;
    this.userMgmt.login(credential).subscribe((res: HttpResponse<any>) => {
      this.isProcessing = false;
      if(res.status === 200) {
      this.authSdrvice.saveUser(res.body);
      // console.log('cookie', this.authSdrvice.getCookie());
      // this.router.navigate(['/category']);
      window.location.href = '/category';
      
      }
      console.log(res, this.authSdrvice.getCookie());

    });
  }

  
}
