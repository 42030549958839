<!-- BEGIN: Header-->
<nav
class="header-navbar navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow fixed-top navbar-light navbar-shadow navbar-brand-center"
>
<div class="navbar-wrapper">
  <div class="navbar-header">
    <ul class="nav navbar-nav flex-row">
      <li class="nav-item d-md-none">
        <a
          [style.margin-left.px]="40"
          class="nav-link open-navbar-container"
          data-toggle="collapse"
          data-target="#navbar-mobile"
          (click)="toggleSidebar()"
          ><i class="fa fa-bars"></i
        ></a>
      </li>
      <li class="nav-item">
        <a class="navbar-brand" href="#"
          ><img
            class="brand-logo"
            alt="modern admin logo"
            src="assets/images/logo/logo.png"
          />
          <h3 class="brand-text">Maverick</h3></a
        >
      </li>
    </ul>
  </div>
  <div class="navbar-container content">
    <div class="collapse navbar-collapse" id="navbar-mobile">
      <ul class="nav navbar-nav mr-auto float-left">
        <li class="nav-item d-none d-md-block">
          <a
            [style.margin-left.px]="sidebarWidth"
            class="nav-link nav-menu-main menu-toggle hidden-xs"
            (click)="toggleSidebar()"
            ><i class="fa fa-bars"></i
          ></a>
        </li>
      </ul>
      <!-- Right Aligned Category2 Dropdown -->
      <ul class="nav navbar-nav ml-auto">
        <li class="nav-item">
          <div class="chart-setting-container">
            <label for="category" class="chart-setting-label">Country</label>
            <select
              id="category"
              class="chart-setting-select"
              [(ngModel)]="selectedCountry"
              (change)="setCountryOption($event)"
            >
              <option
                *ngFor="let option of dataCountryOptions"
                [value]="option"
                [selected]="option === selectedCountry"
              >
                {{ option }}
              </option>
            </select>

            <label for="category2" class="chart-setting-label">Category</label>
            <select
              id="category2"
              class="chart-setting-select"
              [(ngModel)]="selectedCategory"
              (change)="setCategoryOption($event)"
            >
              <option
                *ngFor="let option of dataCategoryOptions"
                [value]="option"
                [selected]="option === selectedCategory"
              >
                {{ option }}
              </option>
            </select>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
</nav>
<!-- END: Header-->

<!-- END: Header-->

<!-- BEGIN: Main Menu-->

<div
  [style.width.px]="sidebarWidth"
  id="mySidebar"
  class="sidebar"
  data-scroll-to-active="true"
>
  <a [routerLink]="['/category']"
    routerLinkActive="active-link"
    [ngClass]="{ active: isActive('/category') }">
    <!-- <i class="mbri-target"></i> -->
    <!--          <i class="fa fa-list"></i>-->
    <img
      ngSrc="assets/images/svg/ic-nav-categories.svg"
      alt="Expand"
      height="24"
      width="24"
    />
    <span class="menu-title" data-i18n="Category">
      {{ sidebarWidth === 100 ? "" : "Categories" }}
    </span></a>
  <a [routerLink]="['/segment']"
    routerLinkActive="router-link-active"
    [ngClass]="{ active: isActive('/segment') }">
    <!-- <i class="mbri-sites"></i> -->
    <img
      ngSrc="assets/images/svg/ic-nav-segments.svg"
      alt="Expand"
      height="24"
      width="24"
    />
    <span class="menu-title" data-i18n="Segments">{{
      sidebarWidth === 100 ? "" : "Segments"
    }}</span></a>
  <a
    [routerLink]="['/manufacturer']"
    routerLinkActive="router-link-active"
    [ngClass]="{ active: isActive('/manufacturer') }">
    <!-- <i class="mbri-website-theme"></i> -->
    <img
      ngSrc="assets/images/svg/ic-nav-factory.svg"
      alt="Expand"
      height="24"
      width="24"
    />
    <span class="menu-title" data-i18n="Manufacturers">{{
      sidebarWidth === 100 ? "" : "Manufacturers"
    }}</span></a>
  <a [routerLink]="['/brand']"
    routerLinkActive="router-link-active"
    [ngClass]="{ active: isActive('/brand') }">
    <img
      ngSrc="assets/images/svg/ic-nav-brands.svg"
      alt="Expand"
      height="24"
      width="24"
    />
    <span class="menu-title" data-i18n="Brands">{{
      sidebarWidth === 100 ? "" : "Brands"
    }}</span></a>
  <a [routerLink]="['/report']"
    routerLinkActive="router-link-active"
    [ngClass]="{ active: isActive('/report') }">
    <img
      ngSrc="assets/images/svg/ic-build-alt.svg"
      alt="Expand"
      height="20"
      width="20"
    />
    <span class="menu-title" data-i18n="Report">{{
      sidebarWidth === 100 ? "" : "Report"
    }}</span></a>
</div>

<!-- END: Main Menu-->
<!-- BEGIN: Content-->
<div id="main"
  class="app-content content"
  [style.margin-left.px]="screenWidth > 620 ? sidebarWidth : 0">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row"></div>
    <div class="content-body">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
<!-- END: Content-->

<!-- BEGIN: Footer-->
<footer
  [style.margin-left.px]="screenWidth > 620 ? sidebarWidth : 0"
  class="footer footer-static footer-light navbar-border navbar-shadow"
>
  <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2">
    <span class="float-md-left d-block d-md-inline-block"
      >Copyright &copy; {{ year }}
      <a class="text-bold-800 grey darken-2" href="#" target="_blank"
        >Maverick</a
      ></span
    ><span class="float-md-right d-none d-lg-block"
      ><i class="ft-heart pink"></i><span id="scroll-top"></span
    ></span>
  </p>
</footer>
<!-- END: Footer-->
