import { Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { MainNavComponent } from './components/layout/main-nav.component';
import {ReportOverviewComponent} from "./components/screens/reports-overview/reports-overview.component";

export const routes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: '', component: MainNavComponent, children: [
        {
          path: '',
          loadComponent: () => import('./components/screens/category-overview/category-overview.component').then(c => c.CategoryOverviewComponent)
        },
        {
          path: 'category',
          loadComponent: () => import('./components/screens/category-overview/category-overview.component').then(c => c.CategoryOverviewComponent)
        },
        {
          path: 'segment',
          loadComponent: () => import('./components/screens/segment-overview/segment-overview.component').then(c => c.SegmentOverviewComponent)
        },
        {
          path: 'manufacturer',
          loadComponent: () => import('./components/screens/manufacturer-overview/manufacturer-overview.component').then(c => c.ManufacturerOverviewComponent)
          },
        {
          path: 'brand',
          loadComponent: () => import('./components/screens/brand-overview/brand-overview.component').then(c => c.BrandOverviewComponent)
          },
        {
          path: 'report',
          loadComponent: () => import('./components/screens/reports-overview/reports-overview.component').then(c => c.ReportOverviewComponent)
          },
        { path: '**', redirectTo: '', pathMatch: 'full' }
      ]}
];
