import { Component, HostListener, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { SharedModule } from '../../modules/shared/shared.module';
import { NgClass, NgForOf, NgIf, NgOptimizedImage } from '@angular/common';
import {getCascadedValuesFromDataset, getUniqueColumnValuesFromDataset} from '../../utils/data_wranggler';
import {cacheKeySelectedCategory, cacheKeySelectedCountry} from '../../utils/constants';
import {DataService} from "../../services/filters.service";
import {BaseComponent} from "../../base/base.component";
import {DatasetProviderService} from "../../services/dataset-provider.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-main-nav',
  standalone: true,
  imports: [RouterModule, SharedModule, NgClass, NgForOf, NgIf, NgOptimizedImage],
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent extends BaseComponent implements OnInit {
  isSidebarExpanded = window.innerWidth <= 620;
  storageSubscription: Subscription;
  year = new Date().getFullYear();
  screenWidth: number;
  screenHeight: number;
  sidebarWidth = 0;
  dataCategoryOptions = [];
  dataCountryOptions = [];
  selectedCategory = '';
  selectedCountry = '';
  dataset: any[] = []

  constructor(private router: Router, dataService: DataService, datasetService: DatasetProviderService) {
    super(datasetService, dataService);
    this.updateSidebarWidth();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateSidebarWidth();
  }

  async updateSidebarWidth() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.toggleSidebar();
  }

 ngOnInit() {
    this.initializers().then((data) => {
      this.dataset = this.mDataset;
      this.updateFilters();
    })

    this.storageSubscription = this.dataService.watchStorage().subscribe((data) => {
      //this.updateFilters();
      this.updateFilters();
    });
  }

  async updateFilters(){
    this.dataCountryOptions = getUniqueColumnValuesFromDataset(this.dataset, 'country');
    this.selectedCountry = this.dataService.hasData(cacheKeySelectedCountry)
      ? this.dataService.getData(cacheKeySelectedCountry)
      : this.dataCountryOptions[0];

    this.dataCategoryOptions = getCascadedValuesFromDataset(this.dataset, 'country', this.selectedCountry, 'category');
    this.selectedCategory = this.dataService.hasData(cacheKeySelectedCategory)
      ? this.dataService.getData(cacheKeySelectedCategory)
      : this.dataCategoryOptions[0];
  }

  isActive(url: string): boolean {
    return this.router.url === url;
  }

  toggleSidebar() {
    if (this.screenWidth <= 620) {
      this.toggleMobileSidebar();
    } else {
      this.toggleWebSidebar();
    }
  }

  toggleMobileSidebar() {
    if (this.isSidebarExpanded) {
      this.sidebarWidth = 0;
    } else {
      this.sidebarWidth = 250;
    }
    this.isSidebarExpanded = !this.isSidebarExpanded;
  }

  toggleWebSidebar() {
    if (this.isSidebarExpanded) {
      this.sidebarWidth = 100;
    } else {
      this.sidebarWidth = 250;
    }
    this.isSidebarExpanded = !this.isSidebarExpanded;
  }

  setCategoryOption(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.selectedCategory = selectedValue;
    this.dataService.setData(cacheKeySelectedCategory, selectedValue);
  }

  setCountryOption(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.selectedCountry = selectedValue;
    this.dataService.setData(cacheKeySelectedCountry, selectedValue);
  }
}
