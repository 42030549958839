import { Injectable } from '@angular/core';
import { DataProviderService } from './data-provider.service';
import { ConstantValuesService } from './constant-values.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(
    private dataProvider: DataProviderService,
    private constantValues: ConstantValuesService
  ) { }
  login(credential) {
    return this.dataProvider.createNoTokenNew(this.constantValues.LOGIN, credential);
  }
}
